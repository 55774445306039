<template>
  <div class="page">
    <div v-show="showShowYe" class="page">
      <header class="header_sy">
        <ul>
          <li class="li1">
            <img src="@/icon/back_white.png" alt @click="back">
          </li>
          <li class="li2">{{ class_name }}</li>
          <li v-show="false">
            <div class="menu">
              <van-icon name="search" size="16px" class="ico" />
              <span />
              <van-icon name="cross" size="16px" class="ico" />
            </div>
          </li>
        </ul>
      </header>
      <div class="jixu">
        <div class="die">
          <!-- <div class="die1"></div>
          <div class="die2"></div>-->
          <div class="jindu">
            <canvas id="canvas" width="600" height="600" class="canvas"></canvas>
            <!--
                     <div class="baizi"></div>
            -->
          </div>
          <div class="dibu">
            <div class="chakan" @click="chengji">
              <img src="../../icon/成绩单.png" alt @click="chengji">

              <span @click="chengji">查成绩单</span>
            </div>
            <div class="zongchengji">
              <p class="p1">{{ score.sum_score }}</p>
              <p class="p2">我的总成绩</p>
            </div>
            <div class="tongxue" @click="chakan1">
              <img src="../../icon/同学.png">
              <span>查看同学</span>
            </div>
          </div>
        </div>
      </div>
      <div class="hiu">
        <div class="content_top">
          <div  class="jiaxue">
            <div class="jiaxue_top">
              <p>教学团队</p>
            </div>
            <div class="jiaxue_bottom">
              <div v-for="(item, index) in team" :key="index" class="teacher_list">
                <img class="teacher_head" :src="item.pic" alt>
                <p class="t_name">{{ item.username }}</p>
                <p class="ty_name">{{ item.type_name }}</p>
              </div>
            </div>
          </div>
          <div v-if="is_buy&&is_praxis" class="smart" @click="go_exercise">
            <div class="left">
              <img src="@/assets/ZNST.png" alt="">
              <span>智能刷题</span>
            </div>
            <div class="right">
              <van-icon name="arrow" />
            </div>
          </div>
          <!--直播预告-->
          <div v-show="live_list_show" class="zb_title">
            <p class="my_curriculum_border"></p>
            <b style="font-size: 14px">直播预告</b>
          </div>

          <div v-show="live_list.length>0" class="live_yu_gao">
            <ul class="main_title main_title_width_dengyu_1">
              <li
                v-for="(val, key) in live_list"
                :key="key"
                class="morelive_item morelive_item_width_dengyu_1"
              >
                <a
                  href="javascript:void(0);"
                  class="live_item morelive_item_width_dengyu_1"
                  @click="go_room(val)"
                >
                  <div class="live_left">
                    <img :src="val.avatar">
                    <span class="teacher_name">{{ val.teacher_name }}</span>
                  </div>
                  <div class="live_right">
                    <div class="div_content">
                      <p class="more-title">{{ val.live_name }}</p>
                      <p class="sub_title">{{ val.lesson_name }}</p>
                    </div>
                    <p class="more-yugao">
                      <img src="../../icon/zbyg.png" class="imgsnz">
                      <span class="sp_iv">直播预告&nbsp;&nbsp;{{ val.start_time_m }}</span>
                    </p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!--直播日历-->
          <div class="zb_title">
            <p class="my_curriculum_border"></p>
            <b style="font-size: 14px">直播日历</b>
          </div>
          <div style="padding-bottom: 80px;  background: #f7f7f7;" v-show="live_date.length>0">
            <div class="div_item_live"  v-for="(item, key) in live_date" :key="key" >
              <div class="div_content_live">
                <p class="p_title_live">{{item.live_name}}</p>
                <p class="sign_status_live"><span>签到状态: </span><span>{{item.is_live ==1 ? '已签到':'未签到'}}</span></p>
                <div class="div_live_time">
                  <img src="https://edu.zhulong.com/img/pop-yugao.png" alt="">
                  <span class="p_live_time">直播时间:{{item.start_time}}</span>
                </div>
              </div>
              <van-button plain type="primary" color="#ee2e2e" v-if="item.wk_id && item.cc_id" @click="unitVideoPlay(lesson_id,item.wk_id)">看回放</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showKeBiao">
      <header class="header_kb">
        <ul>
          <li>
            <img src="../../icon/back_black.png" alt @click="back">
          </li>
          <li class="li2">班级课表</li>
        </ul>
      </header>
      <!--  班级课表 -->

      <div class="lesson_box">
        <!-- 课表，内部区分是否购买 -->
        <div v-loading="tableDataLoading">
          <el-tree class="myTree" :data="catalogue_list" :props="defaultTreeProps" node-key="serial_number" icon-class="el-icon-arrow-right" :default-expanded-keys="defaultExpandKeys" :indent="15" :expand-on-click-node="false" @node-click="handleNodeClick">
            <template slot-scope="{node,data}">
              <!-- 章 -->
              <div v-if="data.courseware_type === 0 || data.courseware_type === 1" class="treeCell">
                <div class="treeTitleBox">
                  <!-- title -->
                  <span class="typeTitle typeTitleBlack">
                    <span v-if="data.serial_number">{{ data.serial_number }} </span>
                    <span v-if="data.item_typestr">[{{ data.item_typestr }}] </span>
                    {{ data.courseware_title }}
                  </span>
                </div>
              </div>

              <!-- 视频 -->
              <div v-else-if="data.courseware_type === 2" class="treeCell">
                <div class="treeTitleBox">
                  <!-- icon -->
                  <img class="typeIcon" src="@/assets/schedule/sp.png" alt="视频">
                  <!-- title -->
                  <span class="typeTitle typeTitleBlack">
                    <span v-if="data.serial_number">{{ data.serial_number }} </span>
                    <span v-if="data.item_typestr">[{{ data.item_typestr }}] </span>
                    {{ data.courseware_title }}
                  </span>
                  <span v-if="data.important" class="important">[{{ data.important }}]</span>
                  <span v-if="!is_buy && data.cc_id && data.is_free === 1" class="redSpan">试学</span>
                </div>
                <div v-if="data.video_duration || data.hd_video_size || data.rate > 0 || data.praxis_num" class="treeDetailBox">
                  <!-- 左侧详情 -->
                  <div class="treeDetailLeft">
                    <span v-if="data.video_duration" class="info1">{{ data.video_duration }}分钟</span>
                    <span v-if="data.hd_video_size" class="info1">{{ data.hd_video_size }}M</span>
                    <span v-if="data.rate > 0" class="info2">已学{{ data.rate }}%</span>
                  </div>
                  <!-- 右侧随堂 -->
                  <div v-if="data.praxis_num" class="treeDetailRight" @click.stop="go_exexcise(data)">
                    <span class="exerciseBtn">随堂练习</span>
                  </div>
                </div>
              </div>

              <!-- 直播 -->
              <div v-else-if="data.courseware_type === 3" class="treeCell">
                <div class="treeTitleBox">
                  <!-- icon -->
                  <img class="typeIcon" src="@/assets/schedule/zb.png" alt="直播">
                  <!-- title -->
                  <span class="typeTitle typeTitleBlack">
                    <span v-if="data.serial_number">{{ data.serial_number }} </span>
                    <span v-if="data.item_typestr">
                      [{{ (data.live_type == 3 && data.cc_id) ? '回放': data.item_typestr }}]
                    </span>
                    {{ data.courseware_title }}
                  </span>
                </div>
                <div class="treeDetailBox">
                  <!-- 左侧详情 -->
                  <div class="treeDetailLeft">
                    <!-- 直播时间、签到 -->
                    <span v-if="(is_xly && class_id) && data.live_time" class="info1">
                      {{ data.live_time }} /
                      <span v-if="data.is_signed > 0">已签到</span>
                      <span v-else>未签到</span>
                    </span>
                    <span v-if="!is_xly && data.live_time" class="info1">
                      {{ data.live_time }} /
                      <span v-if="data.is_signed > 0">已签到</span>
                      <span v-else>未签到</span>
                    </span>
                    <!-- 直播状态 -->
                    <span v-if="data.live_type == 2" class="isLiving">
                      <img src="@/assets/classlist/play.gif">正在直播
                    </span>
                    <span v-if="data.live_type == 1" class="liveNoBegin">
                      <img src="@/assets/classlist/wait.png">未开始
                    </span>
                    <span v-if="data.live_type == 3 && !data.cc_id && data.courseware_id > 0" class="info1">待更新</span>
                  </div>
                  <!-- 右侧随堂 -->
                  <div v-if="data.praxis_num" class="treeDetailRight" @click.stop="go_exexcise(data)">
                    <span class="exerciseBtn">随堂练习</span>
                  </div>
                </div>
              </div>

              <!-- 试卷 -->
              <div v-else-if="data.courseware_type === 4" class="treeCell">
                <div class="treeTitleBox">
                  <!-- icon -->
                  <img class="typeIcon" src="@/assets/schedule/sj.png" alt="试卷">
                  <!-- title -->
                  <span class="typeTitle typeTitleBlack">
                    <span v-if="data.serial_number">{{ data.serial_number }} </span>
                    <span v-if="data.item_typestr">[{{ data.item_typestr }}] </span>
                    {{ data.courseware_title }}
                  </span>
                </div>
                <div v-if="data.review_time && (data.review_type == 2 || data.review_type == 3)" class="treeDetailBox">
                  <!-- 左侧详情 -->
                  <div class="treeDetailLeft">
                    <span v-if="(is_xly && class_id)" class="info1">{{ data.review_time }}</span>
                    <span v-if="!is_xly" class="info1">{{ data.review_time }}</span>
                  </div>
                </div>
              </div>

              <!-- 资料 -->
              <div v-else-if="data.courseware_type === 5" class="treeCell">
                <div class="treeTitleBox">
                  <!-- icon -->
                  <img class="typeIcon" src="@/assets/schedule/zl.png" alt="资料">
                  <!-- title -->
                  <span class="typeTitle typeTitleBlack">
                    <span v-if="data.serial_number">{{ data.serial_number }} </span>
                    <span v-if="data.item_typestr">[{{ data.item_typestr }}] </span>
                    {{ data.courseware_title }}
                  </span>
                </div>
                <div class="treeDetailBox">
                  <!-- 左侧详情 -->
                  <div class="treeDetailLeft">
                    <span class="info1">资料
                      <span v-if="data.filesize">{{ data.filesize > 0 ? (data.filesize / 1024 / 1024).toFixed(2) : 0 }} M</span>
                    </span>
                  </div>
                  <!-- 右侧随堂 -->
                  <div v-if="data.praxis_num" class="treeDetailRight" @click.stop="go_exexcise(data)">
                    <span class="exerciseBtn">随堂练习</span>
                  </div>
                </div>
              </div>

              <!-- 作业 -->
              <div v-else-if="data.courseware_type === 6" class="treeCell">
                <div class="treeTitleBox">
                  <!-- icon -->
                  <img class="typeIcon" src="@/assets/schedule/zy.png" alt="作业">
                  <!-- title -->
                  <span class="typeTitle typeTitleBlack">
                    <span v-if="data.serial_number">{{ data.serial_number }} </span>
                    <span v-if="data.item_typestr">[{{ data.item_typestr }}] </span>
                    {{ data.courseware_title }}
                  </span>
                </div>
                <div class="treeDetailBox">
                  <!-- 左侧详情 -->
                  <div class="treeDetailLeft">
                    <span v-if="data.close_time" class="info1">{{ data.close_time }} {{ data.is_task !=1 ? '已截止' : '截止' }}</span>
                    <span v-else class="info1">暂未布置</span>
                  </div>
                  <!-- 右侧随堂 -->
                  <div v-if="data.praxis_num" class="treeDetailRight" @click.stop="go_exexcise(data)">
                    <span class="exerciseBtn">随堂练习</span>
                  </div>
                </div>
              </div>

              <!-- 教材 -->
              <div v-else-if="data.courseware_type === 7" class="treeCell">
                <div class="treeTitleBox">
                  <!-- icon -->
                  <img class="typeIcon" src="@/assets/schedule/jc.png" alt="教材">
                  <!-- title -->
                  <span class="typeTitle typeTitleBlack">
                    <span v-if="data.serial_number">{{ data.serial_number }} </span>
                    <span v-if="data.item_typestr">[{{ data.item_typestr }}] </span>
                    {{ data.courseware_title }}
                  </span>
                </div>
                <div class="treeDetailBox">
                  <!-- 左侧详情 -->
                  <div class="treeDetailLeft">
                    <span class="info1">教材
                      <span v-if="data.size">{{ data.size > 0 ? (data.size / 1024 / 1024).toFixed(2) : 0 }} M</span>
                    </span>
                  </div>
                  <!-- 右侧随堂 -->
                  <div v-if="data.praxis_num" class="treeDetailRight" @click.stop="go_exexcise(data)">
                    <span class="exerciseBtn">随堂练习</span>
                  </div>
                </div>
              </div>

              <!-- 满意度 -->
              <div v-else-if="data.courseware_type === 8" class="treeCell">
                <div class="treeTitleBox">
                  <!-- icon -->
                  <img class="typeIcon" src="@/assets/schedule/myd.png" alt="满意度">
                  <!-- title -->
                  <span class="typeTitle typeTitleRed">
                    {{ data.serial_number }} [满意度调查表]
                    <span v-if="data.extend.type == '0'">期中满意度调查</span>
                    <span v-else>期末满意度调查</span>
                  </span>
                </div>
                <div class="treeDetailBox">
                  <!-- 左侧详情 -->
                  <div class="treeDetailLeft">
                    <span v-if="data.satisfaction_id > 0" class="info1">已完成</span>
                    <span v-else class="info2">请填写</span>
                  </div>
                  <div class="treeDetailBox">
                    <!-- 左侧详情 -->
                    <div class="treeDetailLeft">
                      <span v-if="data.satis_id > 0" class="info1">已完成</span>
                      <span v-else class="info2">请填写</span>
                    </div>
                    <!-- 右侧随堂 -->
                    <div v-if="data.praxis_num" class="treeDetailRight" @click.stop="go_exexcise(data)">
                      <span class="exerciseBtn">随堂练习</span>
                    </div>
                  </div>
                </div>
              </div>

            </template>
          </el-tree>
        </div>

      </div>
    </div>
    <div v-if="showWenDa">
      <header class="header_wd">
        <ul>
          <li>
            <img src="../../icon/back_black.png" alt @click="back">
          </li>
          <li class="li2">
            <van-tabs v-model="active" title-active-color="#ee0a24">
              <van-tab title="我的提问" />
              <van-tab title="全部问答" />
            </van-tabs>
          </li>
          <li v-show="false">
            <img src="../../icon/更多.png" alt>
          </li>
        </ul>
      </header>
      <div class="fuqui" @click="tiwen">提问</div>
      <div v-show="active===0">
        <div class="hui">
          <div class="hui_1"></div>
        </div>
        <div v-for="(item, index) in my_list" :key="index" class="sss">
          <div class="yi">
            <div class="tu">
              <img :src="item.avatar" alt>
            </div>
            <div class="chan">
              <p>{{ item.username ? item.username : '未知用户' }}</p>
              <p>{{ item.create_time }}</p>
            </div>
          </div>
          <div class="hao">{{ item.title ? item.title : '标题' }}</div>
          <div class="hao1">{{ item.content }}</div>
          <div class="hao2">
            <span>{{ item.answer_count }}</span>个回答
          </div>
          <div class="hui">
            <div class="hui_1"></div>
          </div>
        </div>
      </div>

      <div v-show="active===1">
        <div class="hui">
          <div class="hui_1"></div>
        </div>
        <div v-for="(item, index) in main_list" :key="index" class="sss">
          <div class="yi">
            <div class="tu">
              <img :src="item.avatar" alt>
            </div>
            <div class="chan">
              <p>{{ item.username ? item.username : '未知用户' }}</p>
              <p>{{ item.create_time }}</p>
            </div>
          </div>
          <div class="hao">{{ item.title ? item.title : '标题' }}</div>
          <div class="hao1">{{ item.content }}</div>
          <div class="hao2">
            <span>{{ item.answer_count }}</span>个回答
          </div>
          <div class="hui"></div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="activeName" active-color="#ee0a24" inactive-color="#000">
      <van-tabbar-item name="sy" @click="shouye">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon_shouye.active : icon_shouye.inactive">
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="kb" class="daohang2" @click="kebiao1">
        <img src="../../icon/kebiao.png" class="kebiao">课表
      </van-tabbar-item>
      <van-tabbar-item name="wd" @click="wenda">
        <span>问答</span>
        <template #icon="props">
          <img :src="props.active ? icon_wenda.active : icon_wenda.inactive">
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <!-- 试卷弹窗 -->
    <div v-show="isMask" class="mask">
      <div class="maskBox">
        <div class="close">
          <img src="../../assets/error@2x.png" alt @click="close">
        </div>
        <div class="title">
          <div class="imgs">
            <img src="../../assets/schedule/sj.png" alt>
          </div>
          <div class="infos">{{ exam_name }}</div>
        </div>
        <div class="testMsg">
          <span class="testItem">
            <span class="redWord">{{ question_count }}</span>道题
          </span>
          <span class="line"></span>
          <span class="testItem">
            <span class="redWord">{{ exam_score?exam_score:0 }}</span>满分
          </span>
          <span class="line"></span>
          <span class="testItem">
            <span class="redWord">{{ duration }}</span>分钟
          </span>
        </div>
        <div class="btn" :class="false ? 'un_btn' : 'btn'" @click="goReviewinng">开始做题</div>
      </div>
    </div>
  </div>
</template>

<script>
import { banjishouye, getNotice, getLessonInfo, getEvaluationInfo,getClassLiveByLessonId ,getClassTeam,getClassStudentScore} from '@/utils/index1.js'
import { get_wd_allMsg, get_myWT } from '@/api/QA.js'
import tool from '@/utils/tools.js'
import Cookies from 'js-cookie'

export default {
  filters: {
    formatData: tool.formatDate
  },
  data() {
    return {
      is_buy: false, // 是否购买
      is_xly: 0,
      lessonName: '',

      duration: 0, // 考试时间
      exam_name: 0, // 考试名称
      exam_score: 0, // 考试总分
      pass_score: 0, // 及格分数
      question_count: 0, // 题目数
      tableDataLoading: false,
      defaultExpandKeys: [],
      defaultTreeProps: {
        label: 'courseware_title',
        children: 'children'
      },
      catalogue_list: [], // 课表
      isMask: false, // 试卷弹窗
      showShowYe: true,
      showKeBiao: false,
      showWenDa: false,
      currentRate: 10,
      active: 0,
      ctx: '',
      m: 0,
      n: 0,
      // m:4,
      // n:3,
      wd_lesson_id: '',
      zong: '',
      team: [],
      activeNameLesson: ['1'],
      zblist: [1],
      live_list: [], // 直播预报
      live_date: [], // 直播日历
      live_list_show: false,
      score: {
        learn_count: '',
        learn_rate: '',
        sum_score: 0,
        uid: '',
        user_name: ''
      },
      class_id: '',
      lesson_id: '',
      student_id: '',
      class_name: '',
      // 课表
      activeNames: ['0'],
      icon_wenda: {
        active: require('@/icon/wenda_red.png'),
        inactive: require('@/icon/wenda_gray.png')
      },
      icon_shouye: {
        active: require('@/icon/shouye_red.png'),
        inactive: require('@/icon/shouye_gray.png')
      },
      fen: 16,
      // 问答
      list: ['我的问答', '全部问答'],
      activee: 2,
      activeName: 'sy',
      main_list: [],
      my_list: [],
      is_praxis: 0
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%'
    }
  },

  created() {
    // http://m.openwww.zhulong.com/shouye/banji?class_id=2875&lesson_id=20696
    const href = window.location.href

    // 截取参数的那一个片段，即从？开始
    const str = href.substr(href.indexOf('?') + 1, href.length)
    // 将&分隔符分开
    const params = str.split('&')
    // 取出我们想要的第一个code参数
    this.class_id = params[0].split('=')[1]
    this.lesson_id = params[1].split('=')[1]
    this.student_id = params[2].split('=')[1]
    this.class_name = decodeURI(params[3].split('=')[1])
    // this.banji_shouye()
    console.log(this.class_id)
  },
  mounted() {
    this.getLesson()
    this.draw(0, 0)
    this.banji_shouye()
    this.get_Notice()
    this.getClassLiveByLessonId()
  },
  methods: {
    getClassTeamFun(){
      var info={
        class_id:this.class_id,
      }
      getClassTeam(info).then((res)=>{
        if(res.errNo==0){
          this.team = res.result
        }
      })
    },
    getClassStudentScoreFun(){
      var info={
        lesson_id:this.lesson_id,
        class_id:this.class_id,
      }
      getClassStudentScore(info).then((res)=>{
        if(res.errNo==0){
          this.n = res.result.learn_count
          // this.percentage = res.result.learn_rate
          this.score.sum_score = res.result.sum_score
          this.score.learn_count = res.result.learn_count
          this.score.learn_rate = res.result.learn_rate
          this.score.uid = res.result.uid
          this.score.user_name = res.result.user_name
        // this.student_count = res.result.score.student_count
          // this.rankings = res.result.ranking
        }
      }).then(() => {
        this.draw(this.n, this.m)
      })
    },
    // 获取课程信息
    getLesson() {
      var info = {
        lesson_id: this.lesson_id
      }
      getLessonInfo(info).then((res) => {
        if (res.errNo == 0) {
          this.is_buy = res.result.is_buy
          this.is_praxis = res.result.is_praxis
        }
      })
    },
    // 智能刷题
    go_exercise() {
      // window.open(window.origin + '/edu/exerciseMode?review_id=0&lesson_id=' + this.lesson_id + '&obj_type=3')
      window.open(window.origin + '/edu/st?lesson_id=' + this.lesson_id)
    },
    // 随堂练习
    go_exexcise(item) {
      if (item.status !== 1) {
        return this.$toast('该标题没有课件')
      }
      if (this.is_buy || item.is_free) {
        window.open(window.origin + '/edu/exerciseMode?review_id=' + item.exam_id + '&lesson_id=' + this.lesson_id + '&obj_type=' + item.exam_define_obj_type)
        // this.$router.push({
        //   path: '/reviewing',
        //   query: {
        //     lesson_id: this.lesson_id,
        //     video_id: item.courseware_id,
        //     chapter_id: item.courseware_id,
        //     type: item.exam_define_obj_type
        //   }
        // })
      } else {
        this.$toast('请先购买课程')
      }
    },
    // 递归获取默认展开的章节
    recursiveGetSerialNumber(item) {
      let num_keys = []
      if (item && item.children && item.children.length > 0) {
        const oneKey = item.serial_number || ''
        if (oneKey && oneKey.length > 0) {
          // 加当前
          num_keys.push(oneKey)
          // 判断当前子集
          item.children.forEach(e1 => {
            const in_keys = this.recursiveGetSerialNumber(e1)
            num_keys = num_keys.concat(in_keys)
          })
        }
      }
      return num_keys
    },
    // 直播的回放
    liveBackVideo(item) {
      console.log(item.wk_id + '------wk_id------')
      this.unitVideoPlay(this.lesson_id, item.wk_id)
    },
    // 统一点击方法
    handleNodeClick(data) {
      console.log(data)
      const item_type = data.courseware_type
      if (item_type === 1) {
        // 章 不做任何处理
      } else {
        if (data.courseware_id == 0 && item_type != 8) {
          return
        }
        if (data.status !== 1) {
          return this.$toast('该标题没有课件')
        }
        if (this.is_buy || data.is_free) {
          // 已购买
          if (item_type === 2) {
            // 视频
            this.go_video(data)
          } else if (item_type === 3) {
            // 直播
            if (data.wk_id != 0) {
              if (data.live_type == 3) {
                this.liveBackVideo(data)
              } else if (data.live_type == 2) {
                this.go_live(data)
              } else if (data.live_type == 0) {
                this.$toast('直播尚未设置')
              } else {
                this.$toast('直播尚未开始')
              }
            } else {
              this.$toast('直播尚未设置')
            }
          } else if (item_type === 4) {
            // 试卷
            this.testpaper(data)
          } else if (item_type === 5) {
            // 资料
            this.go_zl(data)
          } else if (item_type === 6) {
            // 作业
            const t_url = data.task_url || ''
            if (t_url.length > 0) {
              this.go_zyurl()
            }
          } else if (item_type === 7) {
            // 教材
            this.go_jc(data)
          } else if (item_type === 8) {
            // 满意度
            this.go_myd(data)
          }
        } else {
          // 未购买
          this.$toast('请先购买课程')
        }
      }
    },
    go_zyurl(url) {
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_download_zi_liao.postMessage(url)
        } else {
          this.$jsbridge.callHandler(
            'vue_download_zi_liao',
            url,
            function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        window.open(url)
      }
    },
    // 统一视频播放：直播回放、视频播放
    unitVideoPlay(lesson_id, wk_id) {
      if (tool.isInApp()) {
        const videoPath = 'lesson_id=' + lesson_id + '&wk_id=' + wk_id
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_openVideo.postMessage(videoPath)
        } else {
          this.$jsbridge.callHandler(
            'vue_openVideo',
            videoPath,
            function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        if (this.isToLogin()) {
          window.open(window.origin + '/edu/play?from=schedule&lesson_id=' + this.lesson_id + '&wk_id=' + wk_id)
        }
      }
    },
    // 重定向到登录
    isToLogin() {
      var url = location.href
      if (!Cookies.get('uid') || !Cookies.get('username')) {
        if (process.env.NODE_ENV === 'development') {
          window.open(
            `https://m.zhulong.com/ucenter/go_login?redirect=${url}`,
            '_self'
          )
        } else {
          window.open(
            `https://m.zhulong.com/ucenter/go_login?redirect=${url}`,
            '_self'
          )
        }
        return false
      } else {
        return true
      }
    },
    // 跳转视频播放
    go_video(item) {
      // console.log(item)
      this.unitVideoPlay(this.lesson_id, item.courseware_id)
    },
    // 跳转直播
    go_live(item) {
      // console.log(item)
      if (tool.isInApp()) {
        const livePath = 'lesson_id=' + this.lesson_id + '&live_id=' + item.live_id + '&wk_id=' + item.courseware_id
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_openLive.postMessage(livePath)
        } else {
          this.$jsbridge.callHandler(
            'vue_openLive',
            livePath,
            function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        window.open(window.origin + '/edu/room?lesson_id=' + this.lesson_id + '&live_id=' + item.live_id)
      }
    },
    // 资料跳转
    go_zl(item) {
      const url = item.courseware_url || ''
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_download_zi_liao.postMessage(url)
        } else {
          this.$jsbridge.callHandler(
            'vue_download_zi_liao',
            url,
            function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        window.open(url)
      }
    },
    // 教材页面跳转
    go_jc(item) {
      // console.log(item)
      console.log(item)
      const url = item.courseware_url || ''
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_download_zi_liao.postMessage(url)
        } else {
          this.$jsbridge.callHandler(
            'vue_download_zi_liao',
            url,
            function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        window.open(url)
      }
    },
    changeTabs(name, title) {
      console.log(name, title)
      if (name !== 0) {
        if (document.getElementsByClassName('myThumb')[0]) {
          document.getElementsByClassName('myThumb')[0].style.display = 'none'
          this.fixed_box = true
          console.log(this.fixed_box)
        }
      } else if (name == 0) {
        console.log(123456)
        this.fixed_box = false
        document.getElementsByClassName('myThumb')[0].style.display = 'block'
      }
    },
    // 满意度
    go_myd(v) {
      window.open(window.origin + '/edu/questionnaire?lesson_id=' + v.lesson_id + '&lesson_name=' + this.lessonName + '&rate=' + v.extend.type)
    },
    testpaper(item) {
      const review_type = item.review_type || ''
      if (review_type == 2 || review_type == 3) {
        // 判断时间
        if (item.is_review == 1) {
          this.doExamAction(item)
        } else {
          this.$toast('评测时间已过期')
        }
      } else {
        // 其他类型
        this.doExamAction(item)
      }
    },
    // 做题事件
    doExamAction(item) {
      /*const q = {
        courseware_id: item.courseware_id,
        class_id: this.class_id || ''
      }
      getEvaluationInfo(q).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          this.duration = result.duration
          this.exam_name = result.exam_name
          this.exam_score = parseInt(result.exam_score)
          this.pass_score = '0'
          this.question_count = result.question_count
          this.sjId = result._id.$oid
          this.paperType = item.exam_define_obj_type
          this.isMask = true
        } else {
          this.$toast(res.msg)
        }
      })*/
      window.open('https://m.zhulong.com/edu/exerciseReport?schedule_id='+item.id+"&class_id="+this.class_id+"&lesson_id="+this.lesson_id+"&obj_type="+2)
    },
    // 开始做题
    goReviewinng() {
      this.$router.push({
        path: '/my_evaluating',
        query: {
          time: this.duration,
          sjId: this.sjId
        }
      })
      console.log('开始做题')
    },
    // 关闭试卷弹窗
    close() {
      this.isMask = false
    },
    go_room(item) {
      if (tool.isInApp()) {
        // 课程详情
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_openLesson.postMessage(item.url)
        } else {
          this.$jsbridge.callHandler(
            'vue_openLesson'
            , item.url
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        window.location.href = item.url
      }
    },
    /* kebiao_list() {
      var params = {
        lesson_id: this.lesson_id,
        class_id: this.class_id,
        student_id: this.student_id,
        class_name: this.class_name
      }
      banjikebiao(params).then((res) => {
        // todo 需要补充逻辑
        //this.catalogue_list = res.result
        // console.log(res, '课表接口')

      })
    },*/
    // 直播预告
    get_Notice() {
      var params = {
        class_id: this.$route.query.class_id
      }
      getNotice(params).then(res => {
        if (res.result.length > 0) {
          this.live_list_show = true
        }
        this.live_list = res.result
      })
    },
    // 直播预告
    getClassLiveByLessonId() {
      var params = {
        class_id: this.$route.query.class_id,
        lesson_id: this.lesson_id
      }
      getClassLiveByLessonId(params).then(res => {
        if (res.errNo == 0){
          this.live_date = res.result.class_live
        }
      })
    },
    banji_shouye() {
      this.tableDataLoading = true

      var params = {
        class_id: this.class_id,
        lesson_id: this.lesson_id,
        student_id: this.student_id,
        class_name: this.class_name
      }
      banjishouye(params).then((res) => {
        this.tableDataLoading = false
        var data = res.result
        this.is_buy = data.is_buy
        console.log(res.result)
        // this.score = data.score
        // this.team = data.team
        this.m = data.video_count
        // this.n = data.learn_count
        // 班级信息
        const cClass = data.class ? data.class : {}
        this.class_id = cClass.class_id || ''
        // 课表
        console.log(data + '-------------')

        const schedule = data.schedule || []
        this.catalogue_list = schedule
        console.log(this.catalogue_list.length + '-------------')

        this.tableDataLoading = false
        if (schedule && schedule.length > 0) {
          const first = schedule[0]
          const defKeys = this.recursiveGetSerialNumber(first)
          console.log('defKeys', defKeys)
          this.defaultExpandKeys = defKeys
        }

        // 直播数、视频数
        this.live_count = res.result.live_count ? res.result.live_count : 0
        this.video_count = res.result.video_count ? res.result.video_count : 0
        this.video_url = res.result.video_url
        this.getClassTeamFun()
        this.getClassStudentScoreFun()
      })
    },
    back() {
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_openTabbar.postMessage(0)
        } else {
          this.$jsbridge.callHandler(
            'vue_openTabbar'
            , '0'
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        this.$router.go('-1')
      }
    },
    chengji() {
      window.location.href = `/edu/transcript?class_id=${this.class_id}&student_id=${this.student_id}`
      // setTimeout(() => {
      //   this.$router.push({
      //     path: '/chengji',
      //     query: {
      //       class_id: this.class_id,
      //       lesson_id: this.lesson_id,
      //       student_id: this.student_id,
      //       class_name: this.class_name
      //     }
      //   })
      // }, 1000)
    },
    chakan1() {
      this.$router.push({
        path: '/chakan',
        query: {
          lesson_id: this.lesson_id,
          class_id: this.class_id,
          student_id: this.student_id,
          class_name: this.class_name
        }
      })
    },
    kebiao1() {
      this.activeName = 'kb'
      // this.kebiao_list()
      this.showShowYe = false
      this.showKeBiao = true
      this.showWenDa = false
    },
    shouye() {
      this.activeName = 'sy'
      // this.banji_shouye()
      this.get_Notice()
      this.showShowYe = true
      this.showKeBiao = false
      this.showWenDa = false
    },
    wenda() {
      this.activeName = 'wd'
      this.get_all_msg()
      this.get_my_msg()
      this.showShowYe = false
      this.showKeBiao = false
      this.showWenDa = true
    },
    tiwen() {
      this.$router.push({
        path: '/tiwen',
        query: {
          lesson_id: this.$route.query.lesson_id
        }
      })
    },
    // 我的提问
    get_my_msg() {
      var params = {
        page: 1,
        limit: 10,
        lesson_id: this.$route.query.lesson_id
      }
      get_myWT(params).then(res => {
        this.my_list = res.result.list
      })
    },
    // 全部提问
    get_all_msg() {
      var params = {
        page: 1,
        limit: 10,
        lesson_id: this.$route.query.lesson_id
      }
      get_wd_allMsg(params).then((res) => {
        this.main_list = res.result.list
      })
    },

    draw(n, m) {
      var c = document.getElementById('canvas')
      var ctx = c.getContext('2d')
      // 每次开始画之前先清空之前的内容
      ctx.clearRect(0, 0, c.width, c.height)
      // 绘制底部的灰色园
      ctx.beginPath()
      // 灰色圆的宽度
      ctx.lineWidth = 40
      // 灰色圆的颜色
      ctx.strokeStyle = '#ccc'
      // arc 方法 第一个参数是圆心的x轴坐标 第二个参数是圆心Y轴坐标
      // 第三个参数是园的半径 第四个参数是 画圆时的起始位置 第五个参数是结束为止
      // 第六个参数表示方向 false表示顺时针方向画
      ctx.arc(
        280,
        340,
        260,
        (2 * Math.PI) / 3,
        (2 * Math.PI) / 3 + (Math.PI + (2 * Math.PI) / 3),
        false
      )
      ctx.stroke()

      // 绘制灰色园上的红色圆
      ctx.beginPath()
      // 红色圆的线条宽度
      ctx.lineWidth = 40
      // 红色圆的线条颜色
      ctx.strokeStyle = 'red'
      // 设置红色环显示的角度范围为 120度到420度 共300度 用100% 表示
      // 用已经完成的课程数/总课程数乘以100% 表示课程完成的百分比
      // 我们这里用 n表示完成的课程数量 用 m表示所有的课程数量
      // 则完成n个课程表示的百分比是
      // n/m*300 表示完成 n个课程要描绘的度数
      // Math.pI 表示 180度
      // 1 = 180°/π
      ctx.arc(
        280,
        340,
        260,
        (2 * Math.PI) / 3,
        (2 * Math.PI) / 3 + (Math.PI + (2 * Math.PI) / 3) * (n / m),
        false
      )
      // 设置两端为圆角
      ctx.lineCap = 'round'
      ctx.stroke()

      // 插入文本
      ctx.font = '70px sans-serif'
      // 填充红色字体
      ctx.fillStyle = 'red'
      // fillText 第一个参数为填充的内容 第二个参数是填充内容的x轴位置 第三个参数是填充的内容的Y轴位置
      ctx.fillText('继续学习', 140, 280)
      // 文字间的分割线
      ctx.beginPath()
      // moveTo意思是画线的初始位置 第一个参数为x轴位置 第二个参数为Y轴位置
      ctx.moveTo(140, 330)
      // lineTo是连线到下一个位置 第一个参数是x轴的位置 第二个参数是y轴的位置
      ctx.lineTo(440, 330)
      ctx.lineWidth = 3
      ctx.strokeStyle = '#ccc'
      ctx.stroke()

      // 第三行的文本
      ctx.font = '46px sans-serif'
      ctx.fillStyle = '#ccc'
      ctx.fillText(`   已学习${this.n}/${this.m}课时`, 100, 420)
    },

    finish() {
      this.draw(this.n, this.m)
      // eslint-disable-next-line no-undef
      var ctx = c.getContext('2d')
    }
  }
}
//  <p class="yuxuexi">已学习{{n}}/{{m}}课时</p>
</script>

<style lang="scss" scoped>
.div_item_live{
  height: 140px;
  padding: 20px 20px 20px 25px;
  margin: 16px 32px 16px 32px;
  border-radius: 8px;
  display: flex;
  justify-content: start;
  background: white;
  font-size:24px;
  .div_content_live {
    width: 100%;
    .p_title_live{
      margin-top: 6px;
      flex-shrink: 0;
      align-self: center;
      text-align: start;
      display: -webkit-box;
      align-items: center;
      text-overflow: ellipsis;
      overflow : hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .sign_status_live {
      margin-top: 16px;
    }
    .div_live_time {
      margin-top: 16px;
      display: flex;
      align-items: center;
      img{
        margin-right: 10px;
      }

      .p_live_time {

      }
    }
  }
  .van-button{
    height: 64px;
    width: 180px;
    border-radius: 4px;
    align-self: center;
  }

}
  // 课表
  /deep/ .van-collapse-item__content {
    padding: 0 16px;
  }
  // 树形结构列表样式
  .myTree {
    padding: 10px 20px 30px;
    // background-color: red;
    background-color: #fff;
    /deep/.el-tree-node__content {
      background-color: #fff;
      padding: 30px 0;
      height: auto !important;
      border-bottom: 1px solid #ddd;
    }
    /deep/.el-tree-node__content > .el-tree-node__expand-icon {
      padding: 6px;
      position: absolute;
      right: 25px;
      color: #999;
      transform: rotate(90deg) scale(1.5);
    }
    /deep/.el-tree-node__expand-icon.expanded {
      transform: rotate(270deg) scale(1.5);
    }
    /deep/.el-tree-node__expand-icon.is-leaf {
      color: transparent;
      cursor: default;
    }
    .treeCell {
      width: calc(100% - 50px);
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      .treeTitleBox {
        // 和里层的不能删
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        display: flex;
        align-items: center;
        .typeIcon {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }
        .typeTitle {
          font-family: PingFangSC-Regular;
          font-weight: 400;
          line-height: 40px;
          font-size: 34px;
          // 和外部的不能删
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .typeTitleRed {
          color: #ee2e2e;
        }
        .typeTitleBlack {
          color: #333;
        }
        .typeTitleGray {
          color: #999;
        }
        .redSpan {
          margin-left: 10px;
          padding: 2px 20px;
          font-family: PingFangSC-Regular;
          text-align: center;
          display: inline-block;
          background-color: #ee2e2e;
          color: #fff;
          font-size: 28px;
          border-radius: 20px;
        }
        .important {
          margin-left: 10px;
          font-family: PingFangSC-Regular;
          text-align: center;
          display: inline-block;
          color: #999;
          font-size: 28px;
        }
      }
      .treeDetailBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .treeDetailLeft {
          margin-top: 20px;
          font-family: PingFangSC-Regular;
          font-size: 28px;
          color: #999;
          .info1 {
            margin-right: 10px;
          }
          .info2 {
            color: #ee2e2e;
            margin-right: 10px;
          }
          .isLiving {
            margin-right: 10px;
            float: right;
            display: flex;
            align-items: center;
            color: #ee2e2e;
            img {
              margin-right: 6px;
              width: 20px;
            }
          }
          .liveNoBegin {
            margin-right: 10px;
            float: right;
            display: flex;
            align-items: center;
            img {
              margin-right: 6px;
              width: 20px;
            }
          }
        }
        .treeDetailRight {
          margin-top: 25px;
          .exerciseBtn {
            padding: 4px 15px;
            font-family: PingFangSC-Regular;
            text-align: center;
            display: inline-block;
            color: #ee2e2e;
            font-size: 28px;
            border-radius: 30px;
            border: 1px solid #ee2e2e;
          }
          .toTest {
            padding: 5px 15px;
            font-family: PingFangSC-Regular;
            background-color: #ee2e2e;
            text-align: center;
            display: inline-block;
            color: #fff;
            font-size: 28px;
            border-radius: 30px;
          }
        }
      }
    }
  }
  .lesson_box {
    padding-top: 10px;
    height: calc(100vh - 180px);
    overflow-y: scroll;
  }
  // 试卷弹窗
  .mask {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 99;
    .maskBox {
      width: 650px;
      height: 670px;
      background-color: #fff;
      border-radius: 10px;
      margin: 220px auto;
      box-sizing: border-box;
      padding: 30px;
      .close {
        height: 44px;
        img {
          width: 44px;
          height: 44px;
          float: right;
        }
        .title_se {
          display: inline-block;
          font-size: 32px;
        }
      }
      .title {
        padding-top: 20px;
        padding-bottom: 70px;
        width: 100%;
        border-bottom: 1px solid #eee;
        font-size: 28px;

        .imgs {
          display: inline-block;
          vertical-align: middle;
          img {
            width: 30px;
            height: 34px;
          }
        }
        .infos {
          margin-left: 20px;
          display: inline-block;
        }
      }
      .testMsg {
        margin-top: 66px;
        display: flex;
        justify-content: space-around;
        .redWord {
          color: #ee2e2e;
          font-size: 40px;
        }
        .testItem {
          color: #999;
          display: inline-block;
        }
        .line {
          width: 2px;
          height: 44px;
          background: #ddd;
        }
      }
      .btn {
        width: 590px;
        height: 88px;
        text-align: center;
        line-height: 88px;
        background: #ee2e2e;
        color: #fff;
        font-size: 28px;
        border-radius: 10px;
        margin-top: 86px;
      }
      .un_btn {
        background: #999;
      }
      .center_se {
        height: 300px;
        display: flex;
        align-items: center;
        .selectA {
          width: 100%;
          height: 80px;
        }
      }
    }
    .join_box {
      height: 400px;
    }
    .join_p {
      line-height: 50px;
      margin-top: 50px;
      text-align: center;
    }
  }
.main_right {
  height: 189px;
  position: relative;
}
.alarm_clock {
  width: 26px;
  height: 26px;
  position: absolute;
  left: 0;
  bottom: 38px;
}
.my_header {
  width: 100%;
  height: 95px;
  background: #2a292e;
}
.arrow {
  width: 28px;
  height: 48px;
  margin: 24px 0 0px 30px;
  float: left;
}
.title {
  display: inline-block;
  color: #fff;
  margin: 0 auto;
  line-height: 95px;
  // text-align: center;
  padding-left: 250px;
  letter-spacing: 2px;
  font-size: 38px;
}
.main {
  width: 89%;
  height: 189px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  background: #ffffff;
  position: absolute;
  left: 40px;
  bottom: 50px;
}

.main_left {
  width: 186px;
  height: 189px;
  img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: 34px 0 0px 32px;
  }
}
.time {
  color: #909090;
  padding: 38px 0 0 35px;
}
.my_curriculum_border {
  display: inline-block;
  width: 6px;
  height: 34px;
  background: #db403b;
  margin: 5px 0 0 20px;
}
.content_top {
  width: 100%;
  position: absolute;
  top: 210px;
  border-radius: 10px;
  .jiaxue {
    width: 90%;
    background: rgb(255, 255, 255);
    margin: 0 auto;
    border-radius: 12px;
    .jiaxue_top {
      width: 100%;
      height: 80px;
      padding-top: 30px;
      p {
        width: 187px;
        height: 56px;
        border: 2px solid #939393;
        border-radius: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 30px;
        letter-spacing: 1px;
        margin: 0px auto;
      }
    }
  }
  .zb_title {
    display: flex;
    width: 80%;
    height: 60px;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 20px;
    b {
      margin-left: 15px;
    }
  }
  .live_yu_gao {
    width: 100%;
    background: #f7f7f7;
    padding-bottom: 160px;

    .live_yu_gao_content {
      width: 89%;
      height: 189px;
      margin: 0 auto;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      background: #ffffff;
    }
  }
}

.ty_name {
  text-align: center;
  color: #9b9b9b;
}
.t_name {
  text-align: center;
}
.teacher_head {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}
.jiaxue_bottom {
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  // justify-content: space-around;
  overflow: hidden;
  overflow-x: scroll;
}

.yuxuexi {
  position: absolute;
  left: 250px;
  top: 210px;
  color: rgb(209, 202, 202);
}
.baizi {
  width: 160px;
  height: 50px;
  // background: red;
  position: relative;
  top: -400px;
  left: 75px;
  z-index: 999;
}
.xia {
  width: 100%;
  height: 234px;
  background: rgb(255, 255, 255);
}
.shang {
  width: 100%;
  height: 100px;
  background: url(../../icon/教学团队.png) no-repeat;
  background-size: 100% 100%;
}

.p2 {
  color: rgb(209, 202, 202);
}
.p1 {
  font-size: 60px;
  color: red;
  font-weight: 600;
}

.die2 {
  width: 88%;
  height: 50px;
  background: rgba(253, 253, 253, 0.374);
  margin: 0 auto;
  margin-top: -55px;
  border-radius: 10px;
}
.die1 {
  width: 94%;
  height: 40px;
  background: rgba(253, 253, 253, 0.374);
  margin: 0 auto;
  margin-top: -20px;
  border-radius: 10px;
}
.hiu {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  position: relative;
}

.jixu {
  height: 400px;
  width: 100%;
  background: #da5954;
  position: relative;
  z-index: 888;
  .die {
    width: 90%;
    height: 480px;
    background-image: url(../../icon/ic_home_top_bg.png);
    margin: 0 auto;
    // margin-top: 50px;
    position: absolute;
    top: 1rem;
    left: 5%;
    overflow: hidden;
    background-size: 100% 100%;
    .jindu {
      width: 240px;
      height: 300px;
      margin: 0 auto;
      .canvas {
        margin-top: 35px;
        margin-left: -20px;
        width: 290px;
        height: 290px;
      }
    }
    .dibu {
      width: 100%;
      height: 66px;
      margin-top: 65px;
      display: flex;
      justify-content: space-around;
      .zongchengji {
        width: 30%;
        height: 100px;
        // background: chartreuse;
        font-size: 24px;
        margin-top: -30px;
        text-align: center;
        line-height: 50px;
      }
      .chakan {
        width: 30%;
        height: 100%;
        border: 2px solid #ee2e2e;
        border-radius: 50px;
        text-align: center;
        line-height: 66px;
        font-size: 24px;
        color: #ee2e2e;
        display: flex;
        margin-left: 25px;
        align-items: center;
        span {
          display: inline-block;
          margin-left: 7px;
          margin-top: 2px;
        }
        img {
          height: 32px;
          margin-bottom: 4px;
          margin-left: 24px;
        }
      }
    }
    .tongxue {
      width: 30%;
      height: 100%;
      border: 2px solid #ee2e2e;
      border-radius: 50px;
      line-height: 66px;
      color: #ee2e2e;
      font-size: 24px;
      display: flex;
      z-index: 888;
      margin-right: 20px;
      align-items: center;
      img {
        height: 32px;
        margin-left: 24px;
        margin-bottom: 4px;
      }
      span {
        display: inline-block;
        margin-left: 7px;
        margin-top: 2px;
      }
    }
  }
}

.header_sy {
  width: 100%;
  background: #da5954;
  .li2 {
    width: 100%;
    margin-right: 92px;
    height: 50px;
    text-align: center;
    margin-top: 36px;
    // background: red;
    overflow: hidden;
    line-height: 50px;
  }
  .li1 {
    margin-top: 36px;
    padding-left: 32px;
    width: 60px;
  }
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 36px;
    text-align: center;
    li {
      img {
        width: 56px;
        height: 56px;
      }
    }
  }
  .menu {
    width: 160px;
    height: 56px;
    border: 1px solid rgb(253, 253, 253);
    margin-left: 16px;
    border-radius: 60px;
    line-height: 56px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    span {
      display: inline-block;
      height: 40px;
      width: 2px;
      background: white;
    }
  }
}
.kebiao {
  position: fixed;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  bottom: 0.66667rem;
  left: 334px;
  z-index: 666;
  box-shadow: 0.01rem 0rem 0.1rem 5px white;
}
.teach {
  background-color: #fff;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  height: 100%;
}
.teacher_list {
  width: 164px;
  height: 190px;
  flex-shrink: 0;
}
.van-tabbar {
  width: 99.7%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: content-box;
  border: 1px solid rgb(255, 255, 255);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
}
/*课表css*/
.ico {
  margin-left: 10px;
  margin-top: 10px;
}
.xiazai {
  width: 80px;
  height: 80px;
  border: 1px solid rgb(150, 151, 153);
  border-radius: 50px;
  margin-top: 20px;
  margin-left: 30px;
}
.baifen {
  color: rgb(190, 39, 39);
}
.moxing {
  font-size: 34px;
  font-weight: 500;
  color: #000;
  margin-top: 16px;
}
.daohang2 {
  display: flex;
  justify-content: center;
  padding-top: 34px;
}
.lia {
  width: 455px;
  height: 100%;
  // background: red;
}
.shipin {
  width: 98%;
  height: 124px;
  // background: skyblue;
  margin: 0 auto;
  display: flex;
  margin-top: 6px;
  border-bottom: 1px solid rgb(201, 191, 191);
  img {
    width: 90px;
    height: 130%;
    margin-left: -2px;
  }
}

.header_kb {
  width: 100%;
  height: 88px;
  .li2 {
    height: 88px;
    margin-top: 6px;
    margin-left: 232px;
    line-height: 88px;
    color: black;
    overflow: hidden;
  }
  ul {
    height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 36px;
    text-align: center;

    li {
      display: flex;
      align-items: center;
      height: 88px;
      img {
        margin-left: 20px;
        width: 64px;
        height: 64px;
      }
    }
  }
}
.fuqui {
  width: 100px;
  height: 100px;
  background: red;
  line-height: 100px;
  text-align: center;
  position: absolute;
  bottom: 160px;
  right: 60px;
  border-radius: 50px;
  font-size: 30px;
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 15px 0px #ccc;
  border: none;
  letter-spacing: 2px;
}
.www {
  width: 100%;
  height: 6vh;
}

.sss {
  width: 100%;
  height: 330px;
  margin: 10px 0px;
  overflow: hidden;
  .tu {
    width: 70px;
    height: 70px;
    background: seagreen;
    border-radius: 50px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }
  .chan {
    width: 100%;
    line-height: 70px;
    font-size: 26px;
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777777;
    p {
      display: inline-block;
    }
  }
  .yi {
    height: 70px;
    margin: 0px 30px;
    display: flex;
  }
  .hao {
    margin: 10px 30px 0px 30px;
    height: 72px;
    text-align: left;
    font-size: 30px;
    line-height: 72px;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .hao1 {
    margin: 10px 30px 0px 30px;
    height: 72px;
    text-align: left;
    font-size: 26px;
    line-height: 72px;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .hao2 {
    height: 50px;
    text-align: left;
    margin: 10px 30px 0px 30px;
    font-size: 24px;
    color: #b0b0b0;
  }
}
.hui {
  width: 100%;
  height: 20px;
  background: #f5f5f5;
  position: relative;
}

.wen {
  width: 9.666667rem;
  height: 200px;
  background: red;
  margin-left: -160px;
  margin-top: 10px;
}

.cha {
  width: 160px;
  height: 60px;
  border: 1px solid rgb(253, 253, 253);
  margin-left: 16px;
  border-radius: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-around;
}
.header_wd {
  .li2 {
    width: 480px;
    height: 100%;
    margin-left: 70px;
    margin-top: 20px;
  }
  width: 100%;
  height: 110px;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(207, 200, 200);
  ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 40px;
    text-align: center;
    li:first-child {
      display: flex;
      align-items: center;
      height: 100%;
    }
    img {
      margin-left: 20px;
      width: 64px;
      height: 64px;
    }
  }
}
.page {
  width: 100%;
  height: calc(100% - 140px);
}

.main_title_width_dengyu_1 {
  padding-bottom: 34px;
}
.main_title {
  font-size: 30px;
  color: #898888;
  letter-spacing: 1px;
  height: 100%;
  margin: 0px 30px;
  align-items: center;
  flex-shrink: 0;

  .morelive_item_width_dengyu_1 {
    width: 100%;
    margin-top: 30px;
  }

  .morelive_item_width:last-child {
    margin-left: 34px;
  }

  .morelive_item {
    height: 220px;
    border-radius: 12px;
    background: white;
    .live_item {
      height: 100%;
      display: flex;

      .live_left {
        padding-top: 5px;
        margin-top: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;

        .teacher_name {
          font-size: 24px;
          color: #999999;
        }

        & > img {
          width: 79px;
          height: 79px;
          // display: inline-block;
          overflow: hidden;
          border-radius: 50%;
          margin: 10px 30px 3px 30px;
          border: 0 solid #e6e6e6;
        }
      }

      .live_right {
        height: 184px;
        width: 100%;
        padding-top: 45px;
        padding-bottom: 10px;
        display: flex;
        padding-right: 30px;
        position: relative;
        flex-direction: column;
        .div_content {
          width: 100%;
        }

        .p {
          display: flex;
          position: absolute;
          bottom: 0px;
          font-size: 24px;
          margin-bottom: 24px;
          align-items: center;
          .sp_iv {
            margin-top: 1px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    p {
      & > img {
        width: 16px;
        vertical-align: sub;
        margin-right: 6px;
      }
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    .more-title {
      // height: 70px;
      // padding-top: 23px;
      font-size: 28px;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      white-space: pre-wrap;

      &:hover {
        color: #ee2e2e;
      }
    }

    .sub_title {
      font-size: 26px;
      color: #999999;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-top: 12px;
      white-space: pre-wrap;

      // margin-bottom:20px;
    }

    .more-yugao {
      position: absolute;
      bottom: 15px;
      color: #adadad;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 24px;
      img {
        width: 32px;
        height: 32px;
      }
      .sp_iv {
        display: inline-block;
        margin-top: 4px;
      }
    }
  }
}

.kebiao_list {
  width: 100%;
  padding-bottom: 120px;
  .myThumb {
    height: 562px;
  }
  .fixedTop {
    position: fixed !important;
    top: 0px !important;
    /deep/.van-icon {
      color: #000 !important;
    }
  }
  .fixedTop.left-arrow {
    top: 0px !important;
  }
  .fixedTop.share_btn {
    top: 0px !important;
    right: 0;
  }
  .popup_div {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .popup_btn {
    width: 100%;
    height: 96px;
    background: #ee2e2e;
    line-height: 96px;
    color: #fff;
    text-align: center;
    font-size: 35px;
    position: absolute;
    bottom: 0;
  }
  .span_complet {
    display: inline-block;
    width: 120px;
    text-align: right;
    font-size: 22px;
  }
  // /deep/ .van-toast{
  //   width: 590px !important;
  //   height: 230px !important;
  // }
  .p_span {
    min-height: 300px;
    .span1 {
      display: inline-block;
      border: 2px solid #ee2e2e;
      background: #f9edeb;
      padding: 5px 15px;
      border-radius: 25px;
      margin: 20px 0 10px 23px;
      color: #ee2e2e;
    }
    span {
      display: inline-block;
      border: 2px solid #f4f4f4;
      background: #f4f4f4;
      padding: 7px 15px;
      border-radius: 25px;
      margin: 20px 0 10px 23px;
      color: #111;
    }
  }
  .kongzt {
    width: 100%;
    img {
      width: 310px;
      height: 310px;
      margin: 0 auto;
      display: block;
      margin-top: 300px;
      margin-bottom: 50px;
    }
    p {
      font-size: 30px;
      // font-weight: 600;
      padding-bottom: 20px;
      color: #999;
      text-align: center;
      padding-top: 30px;
    }
  }
  .assess {
    margin: 23px 21px 200px 19px;
    font-size: 26px;
    .assess-msg {
      width: 100%;
      margin-top: 20px;
      border-bottom: #f8f8f8 2px solid;
      min-height: 185px;
      display: flex;
      justify-content: space-between;
      .nick {
        width: 100%;
        display: flex;
        .nick-img {
          width: 80px;
          height: 80px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .nick-name {
          display: flex;
          flex-direction: column;
          margin-left: 11px;
          width: 88%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .p_title {
    font-weight: 600;
    font-size: 30px;
    padding: 5px 0 0 25px;
  }
  .popup_dl {
    display: flex;
    p {
      padding: 150px 0 0 20px;
      font-weight: 600;
      font-size: 40px;
      color: #ee2e2e;
    }
  }
  .popup_img {
    width: 240px;
    height: 179px;
    background: #ee2e2e;
    margin: 32px 0 0 26px;
  }
  .baitTitle {
    color: #969799;
    font-size: 30px;
    line-height: 90px;
    padding: 0 0 0 51px;
  }
  .videoCount {
    position: absolute;
    right: 30px;
    top: 15px;
    min-width: 36px;
    max-width: 100px;
    height: 36px;
    line-height: 38px;
    background: #ef2d2e;
    border-radius: 18px;
    color: #fff;
    text-align: center;
    padding: 2px 1px 0 1px;
  }
  .vipzk {
    width: 110px;
    height: 30px;
    border: 2px solid #a53c30;
    border-radius: 20px;
    text-align: center;
    line-height: 30px;
    color: #a53c30;
    padding: 2px 2px;
    padding-top: 2px;
    letter-spacing: 1px;
    margin: 28px 0 0 50px;
  }
  .vipzk1 {
    font-size: 20px;
    width: 130px;
    height: 36px;
    border: 2px solid #ee2e2e;
    border-radius: 30px;
    text-align: center;
    line-height: 36px;
    color: #ee2e2e;
    padding: 2px 5px;
    letter-spacing: 1px;
    margin: 24px 0 0 50px;
  }
  .vipzk2 {
    font-size: 24px;
    height: 30px;
    border-radius: 20px;
    text-align: center;
    line-height: 30px;
    color: #b56d29;
    padding-top: 4px;
    letter-spacing: 2px;
    margin: 28px 0 0 50px;
  }
  .zhekTitle {
    font-size: 32px;
    line-height: 92px;
    color: #323233;
    padding-left: 32px;
  }
  .zhek {
    height: 95px;
    width: 100%;
    display: flex;
    // background: antiquewhite;
  }
  .shareFoot {
    width: 100%;
    height: 84px;
    border-top: 1px solid #f6f6f6;
    position: absolute;
    bottom: 0;
    p {
      text-align: center;
      font-size: 30px;
      line-height: 84px;
    }
  }
  .qq {
    width: 84px;
    height: 84px;
  }
  .qqMsg {
    text-align: center;
  }
  .shareTop {
    width: 100%;
    height: 105px;
    p {
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 2px;
      text-align: center;
      line-height: 105px;
    }
  }
  .shareImg {
    width: 100%;
    height: 125px;
  }
  .shareImgMain {
    width: 348px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .gTille {
    font-size: 24px;
    padding: 15px 10px 5px 11px;
    color: #3e3e3e;
  }
  .gridImg {
    transform: scale(0.75);
  }
  .evaluate {
    padding: 25px 0 0 21px;
    font-size: 32px;
    span {
      padding-left: 7px;
    }
  }
  .Praise_rate {
    padding: 25px 21px 0 0;
    font-size: 28px;
    span {
      padding-left: 7px;
    }
  }
  .detail-header {
    height: 100vh;
    .pic {
      width: 100%;
      position: relative;
      img {
        width: 100px;
      }
      .icon {
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1000;

        span.left-arrow {
          padding: 20px 0 0 10px;
          flex: 1;
        }
        span.share_btn {
          padding: 20px 10px 0 0;
        }
      }
      img {
        width: 750px;
      }
    }
    /deep/.van-tab .van-tab__text {
      font-size: 28px;
      color: #333;
      margin-top: 2px;
    }
    .detal-tab {
      .class-detail {
        margin-left: 20px;
        .detail-title {
          margin-top: 45px;
          margin-bottom: 35px;
          font-size: 32px;
          font-weight: bold;
        }
        .detail-study {
          margin-bottom: 26px;
          display: flex;
          justify-content: start;
          //  全是定位！全是定位！！ 改起来恶心死了！！！🤮  什么玩意啊！！
          // 定位是我加的  之前写的代码盒子结构就有大问题 不用定位就只能重写
          //  line-height  text-align display 都不能用  我也恶心他写的结构
          .study {
            // position: absolute;
            // top: -3px;
            // left: 40px;
            font-size: 24px;
            letter-spacing: 1px;
            color: #333;
          }
          // .flw {
          //   position: absolute;
          //   top: -3px;
          //   left: 150px;
          // }
          .goods {
            // position: absolute;
            // top: -3px;
            // left: 190px;
            font-size: 24px;
            letter-spacing: 2px;
            color: #333;
          }
          .student_icon {
            width: 28px;
          }
          .Praise {
            width: 24px;
          }
        }
      }
      // 申请试学
      .btn-group {
        display: flex !important;
        margin-left: 20px;
        margin-bottom: 19px;
        button {
          background-color: #ef2d2e;
          color: #fff;
        }
      }
      .test_study {
        width: 141px;
        height: 48px;
        line-height: 44px;
        border-radius: 28px;
        font-size: 20px;
      }
      .border-bgc {
        width: 100vw;
        height: 19px;
        background-color: #f8f8f8;
        // margin-top:19px;
      }
      .assess-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 55px;
        p:last-child {
          color: #adadad;
        }
      }
      .zl-plan {
        width: 100%;
        height: 90px;
        padding-left: 22px;
        line-height: 90px;
        color: #90c7ae;
        background: #e3f7f0;
      }
    }
  }
  .iv_sc11 {
    width: 20px;
    height: 40px;
  }
  .detail-footer {
    position: fixed;
    z-index: 20;
    display: flex;
    bottom: 0;
    width: 100%;
    height: 97px;
    background-color: #fff;
    .foot-left {
      display: flex;
      align-items: center;
      width: 50%;
      height: 97px;
      border-top: 1px solid #e5e5e5;
      font-size: 22px;
      .left-advice {
        margin: 12px 59px;
        text-align: center;
      }
      .right-like {
        .iv_sc {
          height: 34px;
          width: 34px;
          color: #000;
          margin-top: 8px;
        }
        .tv_sc {
          margin-bottom: 5px;
        }
        text-align: center;
      }
    }
    .footer-right {
      width: 50%;
      height: 98px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #ef2d2e;
    }
  }
  /deep/ .van-tabs__wrap {
    background: #fff;
    border-bottom: 1px solid #dfdfdf;
    border-top: 1px solid #f7f7f7;
    margin-bottom: 10 0 13px auto;
    padding: 0 120px;
    position: relative;
    z-index: 999;
  }
  // 我要评价
  .comment {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 97px;
    background-color: #ef2d2e;
    color: #fff;
  }
  // 课表
  /deep/ .van-collapse-item__content {
    padding: 16px;
  }
  /deep/ .van-collapse-item__content {
    padding-top: 0;
    padding-bottom: 0;
  }
  /deep/ .van-cell__title {
    color: #333;
    font-size: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .item_course {
    display: flex;
    align-items: center;
    height: 96px;
    border-bottom: 1px solid #ebedf0 !important;
    padding: 10px 20px;
  }
  .item_course:nth-last-child(1) {
    border-bottom: none;
  }
  .play {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .play_left {
      display: flex;
      width: 100%;
      // align-items: center;
      .icon_img {
        margin-top: 4px;
      }
      justify-content: flex-start;
      .video_info {
        display: flex;
        font-size: 22px;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        .span_right {
          display: inline-block;
          width: 100%;
          text-align: right;
        }
        .ready_study {
          margin-left: 12px;
          display: inline-block;
          width: 100%;
          text-align: left;
        }
        p {
          text-align: right;
          span {
            display: inline;
            text-align: right;
          }
        }
      }
      /deep/.van-image {
        width: 30px !important;
        height: 30px !important;
      }
      .video_name {
        font-size: 24px;
        padding-top: 10px;
        width: 100%;
        margin-left: 12px;
        flex-direction: column;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .item_title_box {
          width: 100%;
          height: 38px;
          display: flex;
          align-items: center;
          line-height: 1;
          .live_btn_ing {
            display: flex;
            align-items: center;
            border: 1px solid #ee2e2e;
            margin-left: 8px;
            font-size: 12px;
            background: #fff;
            color: #ee2e2e;
            padding: 2px 20px;
            border-radius: 20px;
          }
          .live_btn {
            display: flex;
            align-items: center;
            border: 1px solid #969799;
            margin-left: 8px;
            font-size: 12px;
            background: #969799;
            color: #ee2e2e;
            padding: 2px 20px;
            border-radius: 20px;
          }
        }
        .item_title {
          display: inline-block;
          // max-width:80%;
          padding-left: 6px;
          overflow: hidden;
          font-size: 26px;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #000;
        }
        .title_gray {
          display: inline-block;
          max-width: 80%;
          font-size: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #969799;
          padding: 3px 0 0 0;
        }
        .item_title_zy {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .learn {
          margin-left: 4px;
          font-size: 18px;
          background: #ee2e2e;
          color: #fff;
          padding: 4px 20px;
          border-radius: 20px;
        }
        img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
      }
      /deep/.van-image__img {
        padding-top: 12px;
        width: 26px;
        height: 26px;
      }
    }
    .common_left {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      img {
        width: 56px;
        height: 56px;
      }
      .video_name {
        width: 100%;
        display: flex;
        font-size: 14px;
        flex-direction: row;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .item_title_box {
          height: 40px;
          width: 100%;
          display: flex;
          font-size: 14px;
          align-items: center;
        }
        .item_title {
          display: inline-block;
          // max-width:80%;
          font-size: 14px;
          overflow: hidden;
          padding-left: 6px;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #000;
          span {
            font-size: 24px;
            display: inline-block;
            padding-left: 6px;
          }
        }
        .title_gray {
          display: inline-block;
          max-width: 80%;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #969799;
        }
        .item_title_zy {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .learn {
          margin-left: 4px;
          font-size: 12px;
          background: #ee2e2e;
          color: #fff;
          padding: 0 20px;
          border-radius: 20px;
        }
        img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
      }
    }
    .play_right /deep/ .van-icon {
      font-size: 60px;
      transform: rotate(180deg) !important;
    }
  }
  .detal-tab /deep/ .van-tabs__content {
    padding-bottom: 100px;
  }
  .size_small {
    font-size: 22px;
    display: inline-block;
  }
  .zs_span {
    font-size: 22px;
  }
  // 详情
  .lesson-info /deep/ img {
    display: block;
    width: 100% !important;
    height: 100%;
  }
  .lesson-info {
    // font-size:0;
    p {
      margin: 0;
      padding: 0;
    }
  }
  /deep/ .van-tabs__line {
    background-color: #ee2e2e;
    height: 4px;
  }
  .class_box {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    .tryToLearn {
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-right: 40px;
      .sqsx {
        width: 32px;
        margin-right: 8px;
      }
      .jxxx {
        width: 22px;
        margin-right: 8px;
      }
    }
  }
  /deep/.van-image {
    display: block;
  }
  /deep/.van-tab {
    font-size: 30px;
  }
}
.smart{
  width: 90%;
  background: white;
  border-radius: 12px;
  margin:20px auto;
  border:1px solid  #ee2e2e;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  .left{
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    img{
      margin-right:10px;
    }
  }
  .right{
    font-size:32px;
    color:#ee2e2e;
  }
}
</style>

